import React from "react";
import { Bubbles } from "components/svgs/bubbles";
import { Banner2Wrapper, BannerChildren } from "./banners.styles";

type Props = {
   children?: React.ReactNode
 };

const Banner2: React.FC<Props> = ({ children }) => {
   return (
      <Banner2Wrapper>
         <Bubbles />
         <BannerChildren>{children}</BannerChildren>
      </Banner2Wrapper>
   );
};

export { Banner2 };

import styled from "styled-components";

export const Banner2Wrapper = styled.section`
   position: relative;
   margin-bottom: 3em;
`;

export const BannerChildren = styled.div`
   z-index: 100;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: #f8f8f8;
   min-height: 70vh;
`;
